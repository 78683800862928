<template lang="pug">
form.container(@submit.prevent='submit')
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(:title='schedule.id ? `Programación #${schedule.id}` : `Nueva programación`')
    b-button(type='is-primary' native-type='submit' :loading='isSaving') Guardar
  .card
    .card-content
      .schedule-form.form-horizontal.mb-3
        field(label='Iniciando el' field='startOn' required)
          app-date(id='startOn' v-model='schedule.startOn' type='datetime-local' required)
        field(label='Veces' field='times' required)
          input#times.input(type='number' step='1' placeholder='3 veces' min='1' required v-model.number='schedule.times')
        field(label='Cada' field='each' required)
          input#each.input(type='number' step='1' placeholder='Cada 1' min='1' required v-model.number='schedule.each')
        field(label='Unidad' field='unitOfMeasure' required)
          app-select#unitOfMeasure(:enum='unitOfMeasureEnum' :enum-names='unitOfMeasureDescriptions' v-model.number='schedule.unitOfMeasure' required)
        field(label='Sitios' field='locations')
          table-picker#locations(:data='locations' :isLoading='isLoadingLocations' v-model='schedule.scheduleLocations' multiple )
</template>

<script>
import { format } from 'date-fns'
import schedulesService from '@/services/schedules.service'
import DevicesPicker from '@/components/devices/DevicesPicker'
import TablePicker from '@/components/shared/TablePicker.vue'
import { unitOfMeasureEnum, unitOfMeasureDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'
import locationsService from '@/services/locations.service'
import { inpointSchedules } from '@/router/routes'

const defaultSchedule = () => ({
  startOn: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  scheduleLocations: []
})

export default {
  components: { DevicesPicker, TablePicker },
  data () {
    return {
      schedule: defaultSchedule(),
      who: 'client',
      locations: [],
      unitOfMeasureEnum,
      unitOfMeasureDescriptions,
      isLoading: false,
      isLoadingLocations: false,
      isSaving: false
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getSchedule()
    } else {
      this.getLocations()
    }
  },
  computed: {
    contract () {
      return (this.invoice && this.invoice.contract) || {}
    },
    otherDetails () {
      return (this.invoice && this.invoice.invoiceOtherDetails) || []
    }
  },
  methods: {
    async getSchedule () {
      this.isLoading = true
      const id = Number(this.$route.params.id)
      const result = await schedulesService.getOne(id)
      if (result) {
        this.scheule = result
      }
      this.isLoading = false
    },
    async getLocations () {
      this.isLoadingLocations = true
      const result = await locationsService.getAll()
      if (result) {
        this.locations = result
      }
      this.isLoadingLocations = false
    },
    async submit () {
      this.isSaving = true
      const schedule = {
        ...this.schedule,
        scheduleLocations: this.schedule.scheduleLocations.map(location => ({ locationId: location.id }))
      }
      const result = await schedulesService.save(schedule)
      if (result) {
        toastService.show('Programación guardada')
        this.$router.push({ name: inpointSchedules.name })
      }
      this.isSaving = false
    }
  }
}
</script>

<style scoped>
.schedule-form {
  max-width: 500px;
  width: 100%;
}
</style>
